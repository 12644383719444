import React from "react";
import styled from "styled-components";
import { breakpoints, colors, eases, zIndices } from "../styles/variables";
import ButtonUI from "./buttons/ButtonUI";

interface Props {
  callback?: Function;
  ytVideoCode: string;
}

export default class VideoPlayer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  btnClicked = (): void => {
    if (this.props.callback) {
      this.props.callback();
    }
  };

  render() {
    const iframeSRC = `https://www.youtube.com/embed/${this.props.ytVideoCode}?autoplay=1&rel=0&modestbranding=1&showinfo=0`;

    return (
      <VideoOverlay>
        <div className="overlayBG"></div>
        <div className="iframeOuter">
          <iframe
            className="iframeVideo"
            src={iframeSRC}
            title="YouTube Video Player"
            frameBorder="0"
            allow="autoplay"
            allowFullScreen
          ></iframe>
        </div>
        <div className="closeButton">
          <ButtonUI colorTheme="zing" buttonType="close" callback={this.btnClicked}></ButtonUI>
        </div>
      </VideoOverlay>
    );
  }
}

const VideoOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndices.videoOverlay};
  .overlayBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
  .iframeOuter {
    width: 100%;
    position: relative;
    overflow: hidden;
    max-width: 1280px;
    &:before {
      display: block;
      content: "";
      padding-top: 56.25%;
    }
  }
  .iframeVideo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;
